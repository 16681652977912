import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Privacy() {
  return (
    <Layout>
      <div id="privacy">
        <SEO title="Privacy Policy" />
        <div className="container m-b-lg">
          <div>
            <h2>Privacy Policy</h2>
            <p>
              Congress.Cards Action Fund (“Congress.Cards,” “we,” “our,” or
              “us”) is committed to protecting your privacy. This Privacy Policy
              explains how your personal information is collected, used and
              disclosed by Congress.Cards. This Privacy Policy applies to our
              website, https://congress.cards/, and the Congress.Cards SMS
              service (collectively, our “Service”). By accessing or using our
              Service, you signify that you have read, understood and agree to
              our collection, storage, use and disclosure of your personal
              information as described in this Privacy Policy and our Terms of
              Use.
            </p>
            <p className="lead">
              1. WHAT INFORMATION DO WE COLLECT AND FOR WHAT PURPOSE?
            </p>
            <p>The categories of information we collect can include:</p>
            <p>
              <b>Information you provide to us directly. </b>We may collect
              personal information, such as your name, phone number, location,
              payment information, physical address, and e-mail address when you
              when you sign up for our mailing list, make a donation or
              otherwise communicate with us. We may also collect any
              communications, including via email and SMS/text message, between
              you and Congress.Cards and any other information you provide to
              Congress.Cards, such as your twitter handle. Note that any
              messages transmitted via Congress.Cards may be accessible by
              certain third-party organizations, such as cellular networks and
              SMS gateway services, that may be used to transmit the messages.
              These organizations may have their own rules, policies, and
              security measures controlling who has access to messages
              transmitted through their services.
            </p>
            <p>
              <b>
                Information we receive from third party sites you connect to our
                Service.
              </b>
              We may receive personal information about you from third parties
              and combine that with information we collect through our Service.
              For example, when you interact with us through a social media site
              or third party service, such as when you like, follow or share
              Congress.Cards content on Facebook, Twitter, Pinterest, Instagram
              or other sites, we may receive information from the social network
              including your user ID associated with your social media account,
              and any other information you permit the social network to share
              with third parties. We also receive personal information that you
              provide to us through third party sites you use to communicate
              with Congress.Cards, such as Facebook Messenger, Viber, Slack, and
              Telegram. The data we receive from these third party sites is
              dependent upon that third party’s policies and your privacy
              settings on that third party site. You should always review, and
              if necessary, adjust your privacy settings on third-party websites
              and services before linking or connecting them to our website or
              Service.
            </p>
            <p>
              <b>Information we receive from third parties.</b>From time to
              time, we may receive information about you from third parties and
              other users, such as your publically available voter registration
              or voter history. We may also collect information about you that
              is publicly available. We use this information to operate,
              maintain, and provide to you the features and functionality of the
              Service, effectively respond to your customer service requests and
              support needs, as well as to communicate directly with you, such
              as to send SMS messages with your consent and to send you email
              messages. We may also send you Service-related emails or messages
              (e.g., account verification, change or updates to features of the
              Service, technical and security notices). For more information
              about your communication preferences, see “Control Over Your
              Information” below.
            </p>

            <p className="lead">
              2. HOW WE USE COOKIES AND OTHER TRACKING TECHNOLOGY TO COLLECT
              INFORMATION.
            </p>
            <p>
              We, and our third party partners, automatically collect certain
              types of usage information when you visit our Service, read our
              emails, or otherwise engage with us. We typically collect this
              information through a variety of tracking technologies, including
              cookies, Flash objects, web beacons, embedded scripts,
              location-identifying technologies, file information and similar
              technology (collectively, “tracking technologies”). For example,
              we collect information about your device and its software, such as
              your IP address, browser type, Internet service provider, platform
              type, device type, operating system, date and time stamp, a unique
              ID that allows us to uniquely identify your browser, mobile device
              or your account, and other such information. We also collect
              information about the way you use our Service, for example, the
              site from which you came and the site to which you are going when
              you leave our website, the pages you visit, the links you click,
              how frequently you access the Service, whether you open emails or
              click the links contained in emails, whether you access the
              Service from multiple devices, and other actions you take on the
              Service. When you access our Service from a mobile device, we may
              collect unique identification numbers associated with your device
              or our mobile application (including, for example, a UDID, Unique
              ID for Advertisers (“IDFA”), Google AdID, or Windows Advertising
              ID), mobile carrier, device type, model and manufacturer, mobile
              device operating system brand and model, phone number, and
              depending on your mobile device settings, your geographical
              location data, including GPS coordinates (e.g., latitude and/or
              longitude) or similar information regarding the location of your
              mobile device, or we may be able to approximate a device’s
              location by analyzing other information, like an IP address. We
              may collect analytics data, or use third-party analytics tools
              such as Google Analytics, to help us measure traffic and usage
              trends for the Service and to understand more about the
              demographics of our users. You can learn more about Google’s
              practices at{" "}
              <a
                href="http://www.google.com/policies/privacy/partners"
                target="_blank"
              >
                http://www.google.com/policies/privacy/partners
              </a>
              , and view its currently available opt-out options at
              <a href="https://tools.google.com/dlpage/gaoptout">
                https://tools.google.com/dlpage/gaoptout
              </a>
              . We may also work with third party partners to employ
              technologies, including the application of statistical modeling
              tools, which permit us to recognize and contact you across
              multiple devices. Although we do our best to honor the privacy
              preferences of our users, we are unable to respond to Do Not Track
              signals set by your browser at this time. We use or may use the
              data collected through tracking technologies to: (a) provide
              custom, personalized content and information, including targeted
              content and advertising; (b) recognize and contact you across
              multiple devices; (c) provide and monitor the effectiveness of our
              Service; (d) monitor aggregate metrics such as total number of
              visitors, traffic, usage, and demographic patterns on our website;
              (e) diagnose or fix technology problems; and (f) otherwise to plan
              for and enhance our Service. If you would prefer not to accept
              cookies, most browsers will allow you to: (i) change your browser
              settings to notify you when you receive a cookie, which lets you
              choose whether or not to accept it; (ii) disable existing cookies;
              or (iii) set your browser to automatically reject cookies. Please
              note that doing so may negatively impact your experience using the
              Service, as some features and services on our Service may not work
              properly. Depending on your mobile device and operating system,
              you may not be able to delete or block all cookies. You may also
              set your e-mail options to prevent the automatic downloading of
              images that may contain technologies that would allow us to know
              whether you have accessed our e-mail and performed certain
              functions with it. We and our third party partners may also use
              cookies and tracking technologies for advertising purposes. For
              more information about tracking technologies, please see “Third
              Party Tracking and Online Advertising” below.
            </p>

            <p className="lead">3. SHARING OF INFORMATION</p>
            <p>
              We may share your personal information in the instances described
              below. For further information on your choices regarding your
              information, see the “Control Over Your Information” section
              below. Note that our Service links to various mechanisms for you
              to interact with Congress.Cards on other social media and
              communications platforms, such as Facebook Messenger, Slack,
              Telegram and Viber. Any information and content that you submit in
              public groups and public spaces is viewable by the public, and
              your use of that platform is governed by the privacy practices and
              policies of that social media platform. We may share your personal
              information with:
            </p>
            <ul>
              <li>
                <p>
                  Third parties <i>at your request</i>. For example, you may
                  have the option to share your activities on Congress.Cards
                  with your friends through email, text or on various social
                  media sites. We also share information that you provide to us
                  with third parties, at your request, such as to fill out and
                  submit Congressional letters and web forms on your behalf, to
                  sign petitions, or to join volunteer organizations like Swing
                  Left;
                </p>
              </li>
              <li>
                <p>
                  Third parties related to your postcard, including the
                  recipient and any related partner organization. For example,
                  if a postcard is raising funds for a voting rights
                  organization, Congress.Cards may share your name, email
                  address, and mailing address with that organization.
                </p>
              </li>
              <li>
                <p>
                  Other companies and brands owned or controlled by
                  Congress.Cards, and other companies owned by or under common
                  ownership as Congress.Cards, which also includes our
                  subsidiaries (i.e., any organization we own or control) or our
                  ultimate holding company (i.e., any organization that owns or
                  controls us) and any subsidiaries it owns. These companies
                  will use your personal information in the same way as we can
                  under this Privacy Policy;
                </p>
              </li>
              <li>
                <p>
                  Third-party vendors and other service providers that perform
                  services on our behalf, as needed to carry out their work for
                  us, which may include identifying and serving targeted
                  advertisements, providing SMS messaging services, providing
                  address verification services, processing payments, web
                  hosting, or providing analytic services;
                </p>
              </li>
              <li>
                <p>
                  The public when you provide feedback or user content on our
                  site. For example, if you post user content on our blog or
                  comment on our social media sites, your information, such as
                  your first name, last initial, state of residence, and your
                  comments, may be displayed on our website or on our social
                  media pages;
                </p>
              </li>
              <li>
                <p>
                  Other parties in connection with a company transaction, such
                  as a merger, sale of company assets or shares, reorganization,
                  financing, change of control or acquisition of all or a
                  portion of our business by another company or third party, or
                  in the event of a bankruptcy or related or similar
                  proceedings; and
                </p>
              </li>
              <li>
                <p>
                  Third parties as required by law or subpoena or if we
                  reasonably believe that such action is necessary to (a) comply
                  with the law and the reasonable requests of law enforcement;
                  (b) to enforce our Terms of Use or to protect the security or
                  integrity of our Service; and/or (c) to exercise or protect
                  the rights, property, or personal safety of Congress.Cards,
                  our visitors, or others.
                </p>
              </li>
              <li>
                <p>
                  We may also share information with others in an aggregated or
                  otherwise anonymized form that does not reasonably identify
                  you directly as an individual
                </p>
              </li>
            </ul>

            <p className="lead">4. CONTROL OVER YOUR INFORMATION</p>
            <p>
              <b>How to control your communications preferences: </b>
              You can stop receiving promotional email communications from us by
              clicking on the “unsubscribe link” provided in such
              communications. We make every effort to promptly process all
              unsubscribe requests. You may not opt out of service-related
              communications (e.g., account verification, transactional
              communications, changes/updates to features of the Service,
              technical and security notices). Please see our Frequently Asked
              Questions and Terms of Use for more information.
            </p>
            <p>
              <b>Modifying or deleting your information: </b>
              If you have any questions about reviewing, modifying, or deleting
              your information, or if you want to remove your name or comments
              from our website or publicly displayed content, you can contact us
              directly at info@congress.cards. We may not be able to modify or
              delete your information in all circumstances.
            </p>

            <p className="lead">
              5. THIRD PARTY TRACKING AND ONLINE ADVERTISING
            </p>
            <p>
              <b>Interest Based Advertising. </b>
              We participate in interest-based advertising and use third party
              advertising companies to serve you targeted advertisements based
              on your browsing history. We may share, or we may permit third
              party online advertising networks, social media companies and
              other third party services, to collect, information about your use
              of our website over time so that they may play or display ads on
              our Service, on other devices you may use, and on other websites,
              apps or services, including on Facebook. Typically, though not
              always, the information we share is provided through cookies or
              similar tracking technologies, which recognize the device you are
              using and collect information, including click stream information,
              browser type, time and date you visited the site and other
              information. We and our third party partners use this information
              to make the advertisements you see online more relevant to your
              interests, as well as to provide advertising-related services such
              as reporting, attribution, analytics and market research.
            </p>
            <p>
              <b>Social Media Widgets and Advertising. </b>Our Service may
              include social media features, such as the Facebook Like button,
              Google Plus, LinkedIn, Snapchat, Instagram, Twitter or other
              widgets. These social media companies may recognize you and
              collect information about your visit to our Service, and they may
              set a cookie or employee other tracking technologies. Your
              interactions with those features are governed by the privacy
              policies of those companies. We display targeted advertising to
              you through social media platforms, such as Facebook, Twitter,
              Google+ and others. These companies have interest-based
              advertising programs that allow us to direct advertisements to
              users who have shown interest in our Service while those users are
              on the social media platform, or to groups of other users who
              share similar traits, such as likely commercial interests and
              demographics. These advertisements are governed by the privacy
              policies of those social media companies that provide them.
            </p>
            <b>Your Choices:</b>
            <ul>
              <li>
                <p>
                  <b>Interest-based advertising. </b>To learn about
                  interest-based advertising and how you may be able to opt-out
                  of some of this advertising, you may wish to visit the Network
                  Advertising Initiative’s online resources, at
                  http://www.networkadvertising.org/choices, and/or the DAA’s
                  resources at www.aboutads.info/choices.
                </p>
              </li>
              <li>
                <p>
                  <b>Mobile advertising. </b>
                  You may also be able to limit interest-based advertising
                  through the settings on your mobile device by selecting “limit
                  ad tracking” (iOS) or “opt-out of interest based ads”
                  (Android). You may also be able to opt-out of some – but not
                  all – interest-based ads served by mobile ad networks by
                  visiting http://youradchoices.com/appchoices and downloading
                  the mobile AppChoices app.
                </p>
              </li>
              <li>
                <p>
                  Some of these opt-outs may not be effective unless your
                  browser is set to accept cookies. If you delete cookies,
                  change your browser settings, switch browsers or computers, or
                  use another operating system, you will need to opt-out again.
                </p>
              </li>
            </ul>
            <p>
              Google Analytics and Advertising. We use Google Analytics to
              recognize you and link the devices you use when you visit our site
              or Service on your browser or mobile device, login to your account
              on our Service, or otherwise engage with us. We share a unique
              identifier, like a user ID or hashed email address, with Google to
              facilitate the service. Google Analytics allows us to better
              understand how our users interact with our Service and to tailor
              our advertisements and content to you. For information on how
              Google Analytics collects and processes data, as well as how you
              can control information sent to Google, review Google’s site “How
              Google uses data when you use our partners’ sites or apps” located
              at google.com/policies/privacy/partners/. You can learn about
              Google Analytics’ currently available opt-outs, including
              theGoogle Analytics Browser Add-On. We may also utilize certain
              forms of display advertising and other advanced features through
              Google Analytics, such as Remarketing with Google Analytics,
              Google Display Network Impression Reporting, the DoubleClick
              Campaign Manager Integration, and Google Analytics Demographics
              and Interest Reporting. These features enable us to use
              first-party cookies (such as the Google Analytics cookie) and
              third-party cookies (such as the Doubleclick advertising cookie)
              or other third party cookies together to inform, optimize, and
              display ads based on your past visits to the Service. You may
              control your advertising preferences or opt-out of certain Google
              advertising products by visiting the Google Ads Preferences
              Manager, currently available at google.com/ads/preferences or by
              vising NAI’s online resources atnetworkadvertising.org/choices.
            </p>

            <p className="lead">6. HOW WE STORE AND PROTECT YOUR INFORMATION</p>
            <p>
              Data storage and transfer: Your information collected through our
              website may be stored and processed in the United States or any
              other country in which Congress.Cards or its affiliates or service
              providers maintain facilities. If you are located in the European
              Union or other regions with laws governing data collection and use
              that may differ from U.S. law, please note that we may transfer
              information, including personal information, to a country and
              jurisdiction that does not have the same data protection laws as
              your jurisdiction, and you consent to the transfer of information
              to the U.S. or any other country in which Congress.Cards or its
              parent, subsidiaries, affiliates, or service providers maintain
              facilities and the use and disclosure of information about you as
              described in this Privacy Policy. Keeping your information safe:
              We care about the security of your information and employ
              physical, administrative, and technological safeguards designed to
              preserve the integrity and security of all information collected
              through our website. However, no security system is impenetrable
              and we cannot guarantee the security of our systems 100%. In the
              event that any information under our control is compromised as a
              result of a breach of security, we will take reasonable steps to
              investigate the situation and, where appropriate, notify those
              individuals whose information may have been compromised and take
              other steps, in accordance with any applicable laws and
              regulations.
            </p>

            <p className="lead">7. CHILDREN'S PRIVACY</p>
            <p>
              Congress.Cards does not knowingly collect or solicit any
              information from anyone under the age of 13 on this Service. In
              the event that we learn that we have inadvertently collected
              personal information from a child under age 13, we will delete
              that information as quickly as possible. If you believe that we
              might have any information from a child under 13, please contact
              us at info@congress.cards.
            </p>

            <p className="lead">8. LINKS TO OTHER WEB SITES AND SERVICES</p>
            <p>
              The Service may contain links to and from third party websites of
              our business partners, advertisers, and social media sites and our
              users may post links to third party websites. If you follow a link
              to any of these websites, please note that these websites have
              their own privacy policies and that we do not accept any
              responsibility or liability for their policies. We strongly
              recommend that you read their privacy policies and terms and
              conditions of use to understand how they collect, use, and share
              information. We are not responsible for the privacy practices or
              the content on the websites of third party sites.
            </p>

            <p className="lead">9. HOW TO CONTACT US</p>
            <p>
              If you have any questions about this Privacy Policy or the
              website, please contact us at info@congress.cards.
            </p>
            <p className="lead">10. CHANGES TO OUR PRIVACY POLICY</p>
            <p>
              We may modify or update this Privacy Policy from time to time to
              reflect the changes in our business and practices, and so you
              should review this page periodically. When we change the policy in
              a material manner we will let you know and update the ‘last
              modified’ date at the bottom of this page. If you object to any
              changes, you may close your account. Continuing to use our Service
              after we publish changes to this Privacy Policy means that you are
              consenting to the changes. This privacy policy was last modified
              on July 20, 2019.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
